import React, { useEffect, useState } from 'react';
import '../css/ActivityBadges.css';
import UserBadge from "./UserBadge";

/* global tf_badgesNonce */
function ActivityBadges() {
    const [userLang, setUserLang] = useState(navigator.language );
    const [title1Content, setTitle1Content] = useState('');
    const [title2Content, setTitle2Content] = useState('');
    const [userBadges, setUserBadges] = useState([]);
    const totalBadgesCount = 16; // Nombre total d'emplacements de badges
const [userConnected, setUserConnected] = useState(false);
    useEffect(() => {
        fetch('https://m67.tech/wp-json/tabac_finisher/v1/logged/',{
            credentials: 'include',
            headers: {
                'X-WP-Nonce': tf_badgesNonce.nonce,
                'Content-Type': 'application/json',
                'Accept-Language': navigator.language.slice(0, 2)
            }
        }).then(response => response.json()) // Convertir la réponse en JSON
            .then(data => {
                if (data) {
                    // Si la réponse est true, exécutez le code pour l'utilisateur connecté ici.
                    fetch('https://m67.tech/wp-json/tf_badges/v1/badges/', {
                        credentials: 'include',
                        headers: {
                            'X-WP-Nonce': tf_badgesNonce.nonce,
                            'Content-Type': 'application/json',
                            'Accept-Language': navigator.language.slice(0, 2)
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if(data) {
                                setUserBadges(data);
                            }
                        })

                } else {
                    // Si la réponse est false, redirigez vers la page /login.
                    window.location.href = '/login';
                }
            })
            .catch(error => console.error('Erreur lors de la récupération des données:', error));
        // La logique de récupération des badges reste la même

        // Logique de traduction reste la même
        const translations = {
            en:{
                winningBadges: "Your rewards for your activity on tabac finisher",
                missingBadges :"Collect them all"

            },
            fr:{
                winningBadges: "Vos récompenses pour votre activité sur tabac finisher",
                missingBadges :"Collectionnez les tous"
            },
            es:{
                winningBadges: "Tus recompensas por tu actividad en tabac finisher",
                missingBadges :"Colecciónalos todos"
            },
        };

        setTitle1Content((translations[userLang.slice(0, 2)] || translations["en"]).winningBadges);
        setTitle2Content((translations[userLang.slice(0, 2)] || translations["en"]).missingBadges);
    }, [userLang]);

    // Calculer le nombre d'images vides à afficher
    const emptyBadgesCount = totalBadgesCount - userBadges.length;

    const emptyBadges = Array.from({ length: emptyBadgesCount }, (_, i) => i);

    return (
        <div id='activity-badge-container' className='activity-badge-container madimi-one-regular'>
            {title1Content}
            <div id="image-container" className='accueil-image-container'>
                {userBadges.map((badge, index) => (
                    <UserBadge badge={badge} className='image_badge' key={index}/>
                ))}
            </div>
            <hr/>
            {title2Content}
            <div id="image-container" className='accueil-image-container'>

                { emptyBadges.map(index => (
                    <img src='https://tabacfinisher.com/wp-content/uploads/2024/03/emptyBadge.png'
                         className='image_badge' key={`empty-${index}`} style={{width: '15%'}} alt="Empty Badge"/>
                ))}
            </div>
        </div>
    );
}

export default ActivityBadges;
