import React from 'react';
import '../css/UserBadge.css'

function UserBadge({ badge }) {
    // Optionnel : Vous pouvez formater la date ici si nécessaire
    const displayDate = new Date(badge.date).toLocaleDateString();

    return (
        <div className="user-badge">
            <div className="badge-hover">
                <img src={badge.badgeUrl} alt={badge.badgeName} className="badge-image"/>
                <div className="badge-legend">{badge.badgeTitle} : <div className='badgeTextLegend'> {badge.badgeText}</div></div>
            </div>
            <div className="badge-date">{displayDate}</div>
        </div>
    );
}

export default UserBadge;