import logo from './logo.svg';
import './App.css';
import ActivityBadges from "./components/ActivityBadges";
/* global tf_badgesNonce */
function App() {
  return (
    <div className="App">

      <ActivityBadges />

    </div>
  );
}

export default App;
